<style lang="less" scoped>
.main-con {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
  'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  top: 0;
  left: 0;
  position: fixed;
  width: 1920px;
  height: 1080px;
  transform-origin: left top;
  background: url("../assets/images/bg-sample.png") center;
  background-size: cover;
  .center {
    flex: 1 1 auto;
    width: 1920px;
    height: 1032px;
  }

  .footer {
    flex: 0 0 auto;
    height: 53px;
    z-index: 999;
    .bar {
      margin-top: -50px;
    }
  }
}
</style>
<template>
  <div class="zfx zfx-col  zfx-ai-stretch main-con " :style="transformStyle">
    <zswiper class="center" :opt="swpOpt" ref="main_swp">
      <park-data class="swiper-slide"></park-data>
      <ent-data class="swiper-slide"></ent-data>
      <soon-box-video class="swiper-slide"></soon-box-video>
    </zswiper>
    <div class="footer">
      <div class="bar">
        <light-button text="园区数据" :isCurrent="true" @click="btnClick(0)"></light-button>
        <light-button @click="btnClick(1)" text="企业数据"></light-button>
        <light-button @click="btnClick(2)"  text="视频简介"></light-button>
        <light-button @click="btnClick(3)"  text="中豹荣誉"></light-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent,ref,onMounted} from 'vue';
import LightButton from "../components/core/lightButton.vue";
import ParkData from "@/components/ParkData.vue";
import EntData from "@/components/EntData.vue";
import SoonBoxVideo from "@/components/Info.vue";
import "swiper/swiper-bundle.css";
import SwipeCore, {EffectCube, SwiperOptions} from "swiper";
//@ts-ignore
import {Swiper, SwiperSlide} from "swiper/vue";
import Zswiper from "@/components/core/zswiper.vue";
SwipeCore.use([EffectCube])
const opt:SwiperOptions={
  effect:"cube",
  direction:"horizontal"
}
export default defineComponent({
  setup(){
    const swp=ref(null)

    return {swp}
  },
  name: 'Home2',
  data() {
    return {
      swpOpt:opt,
      transformStyle: {
        transform: "scale(1,1)"
      }
    }
  },
  components: {
    Zswiper,
    SoonBoxVideo,
    EntData,
    ParkData,
    LightButton,
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize)
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize)
  },
  computed: {},
  methods: {
    resize() {
      this.transformStyle = {
        transform: "scale(" + window.innerWidth / 1920 + "," + window.innerHeight / 1080 + ")"
      }
    },
    btnClick(i:number){
      //@ts-ignore
      this.$refs["main_swp"].toPage(i);
    }
  }
});
</script>
