
import {defineComponent} from "vue";

export default defineComponent({
  name: "LightButton",
  props:{
    color:{type:String,default:""},
    text:{type:String, default:"按钮"},
    isCurrent:{type:Boolean,default:false}
  },
  computed:{
    btnCls():any{
      var isC=this.isCurrent
      return {
        btnBase:true,
        btn:!isC,
        current:isC
      }
    }
  }
})
