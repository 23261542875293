
import {defineComponent,ref,onMounted} from 'vue';
import LightButton from "../components/core/lightButton.vue";
import ParkData from "@/components/ParkData.vue";
import EntData from "@/components/EntData.vue";
import SoonBoxVideo from "@/components/Info.vue";
import "swiper/swiper-bundle.css";
import SwipeCore, {EffectCube, SwiperOptions} from "swiper";
//@ts-ignore
import {Swiper, SwiperSlide} from "swiper/vue";
import Zswiper from "@/components/core/zswiper.vue";
SwipeCore.use([EffectCube])
const opt:SwiperOptions={
  effect:"cube",
  direction:"horizontal"
}
export default defineComponent({
  setup(){
    const swp=ref(null)

    return {swp}
  },
  name: 'Home2',
  data() {
    return {
      swpOpt:opt,
      transformStyle: {
        transform: "scale(1,1)"
      }
    }
  },
  components: {
    Zswiper,
    SoonBoxVideo,
    EntData,
    ParkData,
    LightButton,
  },
  mounted() {
    this.resize();
    window.addEventListener("resize", this.resize)
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.resize)
  },
  computed: {},
  methods: {
    resize() {
      this.transformStyle = {
        transform: "scale(" + window.innerWidth / 1920 + "," + window.innerHeight / 1080 + ")"
      }
    },
    btnClick(i:number){
      //@ts-ignore
      this.$refs["main_swp"].toPage(i);
    }
  }
});
