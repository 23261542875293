<template>
  <a href="#" :class="btnCls">
    <span class="sub"/>
    <span class="sub"/>
    <span class="sub"/>
    <span class="sub"/>
    {{text}}
  </a>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "LightButton",
  props:{
    color:{type:String,default:""},
    text:{type:String, default:"按钮"},
    isCurrent:{type:Boolean,default:false}
  },
  computed:{
    btnCls():any{
      var isC=this.isCurrent
      return {
        btnBase:true,
        btn:!isC,
        current:isC
      }
    }
  }
})
</script>

<style lang="less" scoped>
.btnBase{
  position: relative;
  display: inline-block;
  padding: 15px 30px;
  color: #049bf3;
  border:1px solid #044c98;
  border-radius: 5px;
  font-size: 24px;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 4px;
  overflow: hidden;
  margin: 0px 10px 0px 10px;
}
.btn {
  transition: .5s;
  :nth-child(1) {
    filter: hue-rotate(270deg)
  }
  nth-child(2) {
  filter: hue-rotate(110deg)
}
  :nth-child(1) {
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, transparent, #03e9f4);
    animation: animate1 1s linear infinite
  }
  :nth-child(2) {
    top: -100%;
    right: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(180deg, transparent, #03e9f4);
    animation: animate2 1s linear infinite;
    animation-delay: .25s
  }
  :nth-child(3) {
    bottom: 0;
    right: 0;
    width: 100%;
    height: 4px;
    background: linear-gradient(270deg, transparent, #03e9f4);
    animation: animate3 1s linear infinite;
    animation-delay: .50s
  }
  :nth-child(4) {
    bottom: -100%;
    left: 0;
    width: 4px;
    height: 100%;
    background: linear-gradient(360deg, transparent, #03e9f4);
    animation: animate4 1s linear infinite;
    animation-delay: .75s
  }

  @keyframes animate1 {
    0% {
      left: -100%
    }
    50%, 100% {
      left: 100%
    }
  }

  @keyframes animate2 {
    0% {
      top: -100%
    }
    50%, 100% {
      top: 100%
    }
  }
  @keyframes animate3 {
    0% {
      right: -100%
    }
    50%, 100% {
      right: 100%
    }
  }

  @keyframes animate4 {
    0% {
      bottom: -100%
    }
    50%, 100% {
      bottom: 100%
    }
  }
  .sub {
    position: absolute;
    display: block;

  }
}
.btn:hover {
  background: #049bf3;
  color: #ffffff;
  box-shadow: 0 0 5px #a9dbf6, 0 0 25px #049bf3, 0 0 50px #049bf3, 0 0 200px #049bf3;
  -webkit-box-reflect: below 1px linear-gradient(transparent,#0005)
}
.current{
  background: #044c98;
  color: #ffffff;
  box-shadow: 0 0 5px #044c98, 0 0 25px #044c98, 0 0 50px #044c98, 0 0 200px #044c98;
  -webkit-box-reflect: below 1px linear-gradient(transparent,#0005)
}
</style>